<template>
  <main-layout centered followUsBlock>
    <dialogue-card
      :title="$t('dialogue.notActive.title')"
      :description="$t('dialogue.notActive.description')"
      :action-text="$t('dialogue.paymentFailed.action')"
      class="not-active-dialogue"
      @action="tryAgainHandler"
    >
      <template #image>
        <img
          class="payment-failed-dialogue__image"
          src="@/assets/error-payment.svg"
        />
      </template>
    </dialogue-card>
  </main-layout>
</template>

<script>
import BaseLayout from '@/layouts/BaseLayout.vue';
import MainLayout from '@/layouts/MainLayout.vue';
import DialogueCard from '@/components/DialogueCard.vue';

export default {
  name: 'NotActive',
  components: { DialogueCard, BaseLayout, MainLayout },
  data() {
    return {
      backUrl: null,
    };
  },
  methods: {
    tryAgainHandler() {
      if (this.backUrl) {
        window.location.href = this.backUrl;
      } else {
        this.$router.back();
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.backUrl = from.href || null;
    });
  },
};
</script>

<style lang="scss">
.not-active-dialogue {
  margin: 0 auto;
  min-height: 393px;

  &__image {
    height: 64px;
    width: 64px;
  }
}
</style>
