export default {
  manager: 'manager.svg',
  waiter: 'waiter.svg',
  house_keeping: 'house-keeping.svg',
  concierge: 'concierge.svg',
  valet: 'valet.svg',
  therapist: 'therapist.svg',
  reception: 'reception.svg',
  attendant: 'attendant.svg',
  bar: 'bar.svg',
  beautician: 'beautician.svg',
  cafe: 'cafe.svg',
  fitness: 'fitness.svg',
  nails: 'nails.svg',
  salon: 'salon.svg',
  sauna: 'sauna.svg',
  yoga: 'yoga.svg',
  wellness: 'wellness.svg',
};
