<template>
  <div class="modal-rate-us">
    <ui-bottom-sheet
      v-bind="$attrs"
      v-on="$listeners"
      #default="{ close }"
      @opened="handleOpened"
    >
      <div class="modal-rate-us__body">
        <user-avatar
          :payout-id="location.payoutId"
          class="modal-rate-us__avatar"
          size="medium"
          borderless
        />

        <div class="modal-rate-us__title">
          Can you help us<br>by sharing your experience?
        </div>

        <div
          class="modal-rate-us__links"
          :class="{
            'modal-rate-us__links--multiple': !isSingleLink,
          }"
        >
          <ui-button
            v-for="link in links"
            :key="link.name"
            class="modal-rate-us__link"
            :class="{
              'modal-rate-us__link--multiple': !isSingleLink,
              'modal-rate-us__link--active': isServiceSelected(link),
            }"
            ghost
            @click="handleSelectRateService(link)"
          >
            <ui-icon
              :name="link.icon"
              :width="link.width"
              :height="link.height"
            />
          </ui-button>
        </div>

        <div class="modal-rate-us__subtitle">
          Your opinion helps us grow
        </div>

        <ui-button
          class="modal-rate-us__submit"
          :disabled="!currentLink"
          @click="handleSubmit(close)"
        >
          <template v-if="currentLink">
            Rate us on {{ currentLink.name }}
          </template>
          <template v-else>
            Rate us
          </template>
        </ui-button>
      </div>
    </ui-bottom-sheet>
  </div>
</template>

<script setup>
import { ref, computed, getCurrentInstance } from 'vue';
import UserAvatar from '@/components/UserAvatar.vue';
import UiBottomSheet from '@/components/UiBottomSheet.vue';
import UiIcon from '@/components/UiIcon.vue';
import UiButton from '@/components/UiButton.vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

const route = useRoute();

const currentInstance = getCurrentInstance();
const { $amplitude } = currentInstance.appContext.config.globalProperties;

const AMPLITUDE_EVENTS = {
  googleMapsLink: 'GOOGLE_MAPS_LINK_PRESS',
  tripAdvisorLink: 'TRIP_ADVISOR_LINK_PRESS',
  trustPilotLink: 'TRUST_PILOT_LINK_PRESS',
};

const RATE_PROVIDERS = {
  GOOGLE: 'GOOGLE',
  TRIPADVISOR: 'TRIPADVISOR',
  TRUSTPILOT: 'TRUSTPILOT',
};

const GOOGLE_LINK = {
  name: 'Google',
  key: RATE_PROVIDERS.GOOGLE,
};

const TRIPADVISOR_LINK = {
  name: 'Tripadvisor',
  key: RATE_PROVIDERS.TRIPADVISOR,
};

const TRUSTPILOT_LINK = {
  name: 'Trustpilot',
  key: RATE_PROVIDERS.TRUSTPILOT,
};

const SMALL_LINKS = {
  googleMapsLink: {
    ...GOOGLE_LINK,
    icon: 'google-maps',
    width: '113px',
    height: '53px',
  },
  tripAdvisorLink: {
    ...TRIPADVISOR_LINK,
    icon: 'tripadvisor',
    width: '103px',
    height: '51px',
  },
  trustPilotLink: {
    ...TRUSTPILOT_LINK,
    icon: 'trustpilot',
    width: '100px',
    height: '55px',
  },
};

const LARGE_LINKS = {
  googleMapsLink: {
    ...GOOGLE_LINK,
    icon: 'google-maps-large',
    width: '183px',
    height: '82px',
  },
  tripAdvisorLink: {
    ...TRIPADVISOR_LINK,
    icon: 'tripadvisor-large',
    width: '165px',
    height: '80px',
  },
  trustPilotLink: {
    ...TRUSTPILOT_LINK,
    icon: 'trustpilot-large',
    width: '146px',
    height: '80px',
  },
};

const props = defineProps({
  amplitudeData: Object,
  location: Array,
  items: Object,
});

const store = useStore();

const currentLink = ref(null);

const links = computed(() => {
  const tempLinks = Object.entries(props.items);
  const linksData = tempLinks?.length > 1 ? SMALL_LINKS : LARGE_LINKS;

  return tempLinks.map(([key, value]) => ({
    ...linksData[key],
    id: key,
    link: value,
  }));
});

const isSingleLink = computed(() => {
  return links.value?.length === 1;
});

const isServiceSelected = (payload) => {
  return payload.name === currentLink.value?.name;
};

const handleSelectRateService = (payload) => {
  currentLink.value = payload;
};

const handleSubmit = (close) => {
  const linkName = currentLink.value.id;
  const linkUrl = currentLink.value.link;
  const routeStaffId = route.query.staffId;
  const staffPayoutIds = Array.isArray(routeStaffId) ? routeStaffId : [routeStaffId].filter(Boolean);

  $amplitude.event(AMPLITUDE_EVENTS[linkName], props.amplitudeData);
  window.open(linkUrl, '_blank');
  close();

  store.dispatch('trackEvents/trackRateSubmit', { provider: currentLink.value.key, staffPayoutIds });
};

const handleOpened = () => {
  $amplitude.event('FEEDBACK_LINKS_SHOWN', props.amplitudeData);

  if (isSingleLink.value) {
    const [link] = links.value;
    currentLink.value = link;
  }
};
</script>

<style lang="scss">
.modal-rate-us {
  &__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__avatar {
    margin: 25px 0;
  }

  &__title {
    font-family: 'RobotoMedium', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #000000;
  }

  &__links {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 15px;
    row-gap: 15px;
    margin: 30px 0;

    &--multiple {
      margin: 25px 0;
    }
  }

  &__link {
    width: 210px;
    height: 80px;
    box-sizing: border-box;
    border-radius: 12px;

    &--multiple {
      width: 140px;
      height: 80px;
      box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.1);
    }

    &--multiple#{&}--active {
      border: 2px solid #a60400;
    }
  }

  &__subtitle {
    font-family: 'RobotoRegular', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #555555;
    margin-bottom: 25px;
  }

  &__submit {
    font-family: 'RobotoRegular', sans-serif;
    margin-bottom: 30px;
  }
}
</style>
