<template>
  <div>
    <div
      class="status-layout"
      :class="{
        [`status-layout_${status}`]: status,
      }"
    >
      <div class="status-layout__header">
        <user-avatar
          :payout-id="locationPayoutId"
          borderless
          size="medium"
        />

        <div class="status-layout__header-info">
          <div class="status-layout__title">
            {{ showThankYouFeedback ? 'Thanks for your feedback!' : $t(`modal.${status}.title`) }}
          </div>

          <ui-checkbox
            v-if="showReceiptCheckbox"
            :value="showReceiptForm"
            class="receipt__checkbox"
            red
            @input="toggleReceiptForm"
          >
            {{ $t('form.receipt') }}
          </ui-checkbox>
        </div>
      </div>

      <receipt-card
        v-if="showReceiptForm"
        :is-loading="isLoading"
        :customer-email="customerEmail"
        class="status-layout__receipt"
        :meta-config="metaConfig"
      />

      <status-screen-invitation
        v-if="showInvitationBanner"
        :description="BANNER_TEXT[bannerVariant]"
        @submit="invitationSubmitHandler"
      />

      <status-screen-socials
        @link-click="linkClickedHandler"
      />
    </div>

    <modal-rate-us
      v-model="showModalRateUs"
      :items="feedbackLinks"
      :location="location"
      :amplitude-data="data"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { setTransactionHash } from '@/utils/transactionCollisions';
import StatusScreenSocials from '@/components/status/StatusScreenSocials.vue';
import StatusScreenInvitation from '@/components/status/StatusScreenInvitation.vue';
import UserAvatar from '@/components/UserAvatar.vue';
import UiCheckbox from '@/components/UiCheckbox.vue';
import ReceiptCard from './ReceiptCard.vue';
import ModalRateUs from './ModalRateUs.vue';

const BANNER_TEXT = {
  VARIANT_1: 'Boost your <b>earnings by up to 25%,</b><br> motivate your team and reduce admin.',
  VARIANT_2: 'Try split payments and direct tipping for<br><b>fairer and faster team rewards.</b>',
};

export default {

  inheritAttrs: false,

  components: {
    UiCheckbox,
    UserAvatar,
    StatusScreenInvitation,
    StatusScreenSocials,
    ReceiptCard,
    ModalRateUs,
  },

  props: {
    locationPayoutId: {
      type: String,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
    cardPay: {
      type: Boolean,
      required: true,
    },
    feedbackSent: {
      type: Boolean,
      required: true,
    },
    rate: Number,
    isLoading: Boolean,
    data: Object,
    feedbackLinks: Object,
    customerEmail: String,
    metaConfig: Object,
  },

  data: () => ({
    BANNER_TEXT,
    show: true,
    showModalRateUs: false,
    timeoutId: null,
    showReceiptForm: false,
  }),

  computed: {
    ...mapState('user', [
      'location',
    ]),
    bannerUrl() {
      return this.location.bannerUrl;
    },
    bannerVariant() {
      return this.location.bannerVariant;
    },
    typeOfReview() {
      return this.location.typeOfReview;
    },
    showReceiptCheckbox() {
      return !this.cardPay && !this.feedbackSent && this.status === 'success';
    },
    showFeedbackLinks() {
      const reviewTypes = ['PUBLIC_REVIEWS_ONLY', 'STANDARD_AND_PUBLIC_REVIEWS_ONLY'];

      const isReviewTypeOrRateAllowed = this.rate === 5
        || reviewTypes.includes(this.typeOfReview);

      return this.$can('subscription:tips-review-buttons-view')
        && this.feedbackLinks
        && Object.keys(this.feedbackLinks).length !== 0
        && isReviewTypeOrRateAllowed;
    },
    showThankYouFeedback() {
      return !(!this.feedbackSent || this.showFeedbackLinks) || this.isPerfectFeedback;
    },
    isPerfectFeedback() {
      return this.feedbackSent && this.showFeedbackLinks && this.rate !== 5;
    },
    isErrorStatus() {
      return this.status === 'error';
    },
    showInvitationBanner() {
      return Boolean(this.bannerVariant && this.bannerUrl && !this.isErrorStatus);
    },
  },

  methods: {
    ...mapActions('trackEvents', [
      'trackRateShow',
    ]),
    toggleReceiptForm() {
      this.showReceiptForm = !this.showReceiptForm;

      this.$emit('toggle-receipt');
    },
    linkClickedHandler(linkName) {
      this.$emit('clicked-link', linkName);
    },
    saveInfoAboutTransaction() {
      const { locationPayoutId } = this.$route.params;
      const { amount, staffId } = this.$route.query;
      setTransactionHash({ locationPayoutId, amount, staffId });
    },
    invitationSubmitHandler() {
      this.$amplitude.event(`CLICK_BANNER_${this.bannerVariant}`, this.data);
      window.open(this.bannerUrl, '_blank');
    },
  },

  created() {
    this.saveInfoAboutTransaction();
  },

  mounted() {
    this.timeoutId = setTimeout(() => {
      if (!this.showFeedbackLinks) {
        return;
      }

      this.showModalRateUs = true;
    }, 1000);
  },

  beforeDestroy() {
    clearTimeout(this.timeoutId);
  },

  watch: {
    showModalRateUs(show) {
      if (show) {
        const routeStaffId = this.$route.query.staffId;
        const staffPayoutIds = Array.isArray(routeStaffId) ? routeStaffId : [routeStaffId].filter(Boolean);

        this.trackRateShow({ staffPayoutIds });
      }
    },
    showInvitationBanner: {
      immediate: true,
      handler(show) {
        if (show) {
          this.$amplitude.event(`SHOW_BANNER_${this.bannerVariant}`, this.data);
        }
      },
    },
  },
};
</script>

<style lang="scss">
@import "src/assets/sass/constants";

html, body {
  max-width: 100%;
  overflow-x: hidden;
}

.status-layout {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 0 auto;
  max-width: $max-width;

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 21px;

    &-info {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }

  &__title {
    font-size: 32px;
    line-height: 38px;
    font-weight: 500;
  }

  &_error &__title {
    color: #a01212;
  }
}
</style>
