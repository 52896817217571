import api from '@/api';
import router from '@/router';

import factory from '../factory';

export const getPaymentId = () => {
  const { query } = router.currentRoute.value;

  const paymentId = ['paymentId', 'checkoutPaymentId', 'payermaxPaymentId']
    .map((key) => query[key])
    .find((value) => value !== undefined);

  return paymentId;
};

export default factory({

  namespaced: true,

  actions: {
    async trackRateShow({ rootState }, { staffPayoutIds }) {
      try {
        const locationPayoutId = rootState.user?.locationPayoutId;
        const paymentId = getPaymentId();

        if (locationPayoutId) {
          await api.trackRateShow(locationPayoutId, paymentId, staffPayoutIds);
        }
      } catch (e) {
        console.log(e);
      }
    },

    async trackRateSubmit({ rootState }, { provider, staffPayoutIds }) {
      try {
        const locationPayoutId = rootState.user?.locationPayoutId;
        const paymentId = getPaymentId();

        if (locationPayoutId) {
          await api.trackRateSubmit(locationPayoutId, provider, paymentId, staffPayoutIds);
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
});
