<template>
  <ui-modal
    v-bind="$attrs"
    class="modal-terms"
    v-on="$listeners"
  >
    <template v-if="$scopedSlots.activator" #activator="scope">
      <slot name="activator" v-bind="scope" />
    </template>
    <template #default="{ close }">
      <terms-and-conditions-uae v-if="isUAE" />
      <terms-and-conditions-gb v-else />
      <ui-button @click="close">
        {{ $t('modal.terms.button') }}
      </ui-button>
    </template>
  </ui-modal>
</template>

<script>
import UiModal from '@/components/UiModal.vue';
import UiButton from '@/components/UiButton.vue';

import TermsAndConditionsGb from '@/components/TermsAndConditionsGb.vue';
import TermsAndConditionsDe from '@/components/TermsAndConditionsDe.vue';
import TermsAndConditionsUae from '@/components/TermsAndConditionsUae.vue';

export default {

  inheritAttrs: false,

  components: {
    UiModal,
    UiButton,
    TermsAndConditionsGb,
    TermsAndConditionsUae,
  },

  computed: {
    country() {
      return this.$store.state.user.location.country;
    },
    isUAE() {
      return this.country === 'ae';
    },
  },
};
</script>

<style lang="scss">
.modal-terms {
  .document {
    font-family: "RobotoRegular", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.29;
    color: #2c2c2c;

    p {
      margin-bottom: 25px;
    }

    a {
      color: #A01212;
    }

    ul, ol {
      margin-bottom: 25px;
      padding-left: 25px;
    }

    ul {
      list-style-type: disc;
    }

    ol {
      list-style-type: decimal;
    }

    .title, h2 {
      font-family: "RobotoMedium", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 26px;
      color: #000;
      margin-top: 0;
      margin-bottom: 32px;
    }

    .updated-date {
      display: inline-block;
      font-family: "RobotoMedium", sans-serif;
      font-weight: 500;
      color: #808080;
    }
  }
}
</style>
